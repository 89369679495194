export function withCharacterLimit(Component): ComponentType {
    return (props) => {
        // Extract text property
        const { text, ...otherProps } = props
        const limit = 10 // Set character limit

        // Check if text is longer than limit, if so, trim it
        let trimmedText = text
        if (text?.length > limit) {
            trimmedText = text.slice(0, limit).trim() // Trim extra spaces

            // Check if trimmed text ends with dot
            if (trimmedText.endsWith(".")) {
                trimmedText = trimmedText.slice(0, -1).trim() // Remove extra dot if it ends with a dot
            }

            trimmedText += "..." // Add three dots
        }

        return <Component {...otherProps} text={trimmedText} />
    }
}
